import {textPartsField} from "../../utils/jsonparser/fields";

export default {
  SanityFeedEvent: [
    textPartsField("eventTitle"),
    textPartsField("eventSubTitle"),
    "eventDate",
    "seoMeta",
    {
      name: "img",
      valueFrom: "asset.gatsbyImageData",
      rename: "image",
    },
    "alt",
    {
      name: "tileImg",
      valueFrom: "asset.gatsbyImageData",
      rename: "tileImage",
    },
    "tileAlt",
    "cta",
    {name: "_rawDesc", rename: "desc"},
    "ytId",
    {
      name: "_rawDetails",
      rename: "details",
    },
    {name: "eventPerformers", rename: "performer"},
  ],
};
