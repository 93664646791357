import {graphql, useStaticQuery} from "gatsby";
import PropTypes from "prop-types";
import * as React from "react";
import {Helmet} from "react-helmet";

const conditionalElm = (condition, element) => {
  return condition ? [element] : [];
};

function Seo({
  description,
  lang = "en_US",
  meta = [],
  title,
  image,
  url,
  addDefaultTitle = true,
  children,
}) {
  const {site} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;

  const finalMeta = [
    // OG Tags
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    ...conditionalElm(image, {
      property: `og:image`,
      content: image,
    }),
    ...conditionalElm(url, {
      property: `og:url`,
      content: url,
    }),

    // SEO Tags
    {
      name: `description`,
      content: metaDescription,
    },

    // Twitter tags
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata?.author || ``,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    ...conditionalElm(image, {
      property: `twitter:image`,
      content: image,
    }),
    ...conditionalElm(url, {
      property: `twitter:url`,
      content: url,
    }),
  ].concat(meta);

  return (
    <Helmet
      defer={false}
      htmlAttributes={{
        lang,
      }}
    >
      <title>{defaultTitle ? `${defaultTitle} | ${title}` : title}</title>
      {finalMeta.map((meta) => (
        <meta key={meta.name} {...meta} />
      ))}
      {children}
    </Helmet>
  );
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default Seo;
