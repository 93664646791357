// extracted by mini-css-extract-plugin
export var author = "styles-module--author--30s4g";
export var authorImage = "styles-module--authorImage---YhdN";
export var authorName = "styles-module--authorName--2Gy3B";
export var cardDetails = "styles-module--cardDetails--1hjKe";
export var cardImage = "styles-module--cardImage--37azU";
export var cardSection = "styles-module--cardSection--3XVdT";
export var date = "styles-module--date--2jSyE";
export var description = "styles-module--description--1t_w4";
export var designElement = "styles-module--designElement--3NOPC";
export var designation = "styles-module--designation--2NOLL";
export var even = "styles-module--even--3FJHl";
export var featured = "styles-module--featured--3ODYp";
export var featuredTag = "styles-module--featuredTag--27B38";
export var feed = "styles-module--feed--2srsr";
export var feedCard = "styles-module--feedCard--1P82l";
export var meta = "styles-module--meta--1Ue_T";
export var newTag = "styles-module--newTag--T5Fc6";
export var odd = "styles-module--odd--3Mc0W";
export var readingTime = "styles-module--readingTime--vwrVI";
export var series = "styles-module--series--1D7RZ";
export var tag = "styles-module--tag--357-H";
export var tags = "styles-module--tags--RZvX8";
export var title = "styles-module--title--3Jbfu";