import React from "react";

import * as styles from "./styles.module.scss";

const YTVideo = ({youtubeId}) => {
  return (
    <div className={styles.container}>
      {/* Element to maintain aspect ratio of the video which is set as 5:2 here */}
      <svg viewBox='0 0 5 2' />
      <iframe
        className={styles.iframe}
        src={`https://www.youtube.com/embed/${youtubeId}`}
        frameBorder='0'
      />
    </div>
  );
};

export default YTVideo;
