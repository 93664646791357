import {StyleExtractor, get} from "@kubric/utils";
import {useLocation} from "@reach/router";
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";

import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import SeoMetaStuff from "../../components/SeoMetaStuff";
import StructuredData, {
  StructuredDataType,
} from "../../components/StructuredData";
import CTA from "../../components/commons/CTA";
import EmailCTA from "../../components/commons/EmailCTA";
import FormSection from "../../components/commons/FormSection";
import Heading from "../../components/commons/Heading";
import SanityRichText from "../../components/commons/SanityRichText";
import EventCard from "../../components/pages/feed/CardsSection/cards/EventCard";
import mlFieldMap from "../../components/pages/makerslist/fieldmap";
import {useMediaQuery} from "../../hooks/useMediaQuery";
import {parseJSON} from "../../utils/jsonparser";
import YTVideo from "./YTVideo";
import fieldmap from "./fieldmap";
import * as styles from "./styles.module.scss";

const EventDetails = ({pageContext}) => {
  const styler = new StyleExtractor(styles);
  const queryData = useStaticQuery(graphql`
    {
      events: allSanityFeedEvent(
        limit: 3
        sort: {fields: _updatedAt, order: DESC}
      ) {
        nodes {
          slug {
            current
          }
          img {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          alt
          eventTitle {
            label
            bold
            _key
            color {
              value
            }
          }
          id
        }
      }
      mlPage: sanityMlPage {
        mlSections {
          __typename
          ... on SanityMlFormSection {
            _id
            submitAction
            heading {
              label
              bold
              _key
              color {
                value
              }
            }
            controls {
              __typename
              ... on SanityMlButton {
                _key
                label
                bgColor {
                  value
                }
                textColor {
                  value
                }
              }
              ... on SanityMlFormField {
                _key
                label
                fieldName
                type
              }
              ... on SanityMlSelectBox {
                _key
                label
                fieldName
                options {
                  _key
                  label
                  value
                }
              }
            }
          }
        }
      }
    }
  `);
  const events = get(queryData, "events.nodes", []);
  const sections = get(queryData, "mlPage.mlSections", []);
  const formSection = sections.find(
    ({__typename: type}) => type === "SanityMlFormSection"
  );
  const data = pageContext.data;
  const isMobile = useMediaQuery("(max-width: 900px");
  const {
    data: {
      cta,
      desc,
      details,
      eventTitle,
      eventDate,
      image,
      alt,
      ytId,
      eventSubTitle,
      seoMeta,
      performer,
    } = {},
  } = parseJSON(data, fieldmap);
  const formConfig = parseJSON(formSection, mlFieldMap);
  const isPastEvent = new Date().getTime() > new Date(eventDate).getTime();
  const {href: pageUrl} = useLocation();
  return (
    <Layout>
      <SEO title={seoMeta?.title ?? ""} />
      {seoMeta && <SeoMetaStuff data={seoMeta} />}
      <StructuredData
        type={StructuredDataType.EVENT}
        data={{
          name: eventTitle[0]?.label,
          startDate: eventDate,
          image:
            image?.images?.fallback?.src ||
            `https://www.youtube.com/embed/${ytId}`,
          description: desc?.[0]?.children?.[0]?.text || eventTitle,
          url: pageUrl || "https://getmason.io/feed/",
          performer,
        }}
      />
      <div className={styler.get("section1", "mt-24")}>
        <Heading data={eventTitle} theme={{container: styles.heading}} />
        {data.ytId !== null ? (
          <div className='my-8'>
            <YTVideo youtubeId={ytId} />
          </div>
        ) : (
          image && (
            <GatsbyImage
              className='w-full my-4 rounded-lg'
              image={image}
              alt={alt}
            />
          )
        )}
        <Heading
          data={eventSubTitle}
          theme={{container: styles.subheading}}
          h2
        />
        <div className={styles.description}>
          <SanityRichText blocks={desc} />
        </div>
      </div>
      <div
        className={styler.get(
          "section2",
          "event-details full-bleed layouted py-6"
        )}
      >
        <div className={styles.description}>
          <SanityRichText blocks={details} />
        </div>
        {isPastEvent ? (
          <EmailCTA
            data={{
              action: "subscribeFutureEvents",
              cta: isMobile ? "Subscribe" : "Subscribe for future events",
              bgColor: "var(--almost_black)",
              textColor: "white",
            }}
            theme={{
              container: styles.ctaContainer,
            }}
          />
        ) : (
          <CTA
            data={{
              href: cta,
            }}
            theme={{cta: styles.cta2}}
          >
            REGISTER
          </CTA>
        )}
      </div>
      <div
        className='related-events full-bleed layouted py-12'
        style={{backgroundColor: "#FFE6E1"}}
      >
        <div>
          <div className={styles.events}>
            {events.map((event) => {
              return (
                <EventCard
                  data={event}
                  theme={{feedCard: styles.card, cardDetails: styles.cardtitle}}
                />
              );
            })}
          </div>
          <br />
          <CTA
            data={{
              href: "/feed",
            }}
            theme={{cta: styles.cta3}}
          >
            See all events
          </CTA>
        </div>
      </div>
      <FormSection
        data={formConfig.data}
        theme={{formContainer: styles.formContainer}}
      />
    </Layout>
  );
};

export default EventDetails;
